.media-incrivel{
    display:flex;
    margin-bottom:20px;

    @include media-breakpoint-down(md){
        justify-content:center;
    }

    .media-incrivel__text{
        padding-left:11px;

        a{
            display:block;
        }

    }

    &.media-incrivel--tel{

        .media-incrivel__icon{
            padding-top:9px;
        }

        .icone{
            background-image:var(--icone-tel-rodape);
            width:12px;
            height:12px;
        }

    }

    &.media-incrivel--email{
        align-items:center;

        .icone{
            background-image:var(--icone-email-rodape);
            width:15px;
            height:11px;
        }

    }

    &.media-incrivel--gps{
        align-items:center;

        .icone{
            background-image:var(--icone-gps-rodape);
            width:13px;
            height:18px;
        }

    }

    &.media-incrivel--relogio{
        align-items:center;

        .icone{
            background-image:var(--icone-relogio-rodape);
            width:17px;
            height:17px;
        }

    }

}