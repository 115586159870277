@font-face {
    font-family: 'rubikregular';
    src: url('#{$fonts}/Rubik-Regular/rubik-regular-webfont.eot');
    src: url('#{$fonts}/Rubik-Regular/rubik-regular-webfont.eot?#iefix') format('embedded-opentype'),
         url('#{$fonts}/Rubik-Regular/rubik-regular-webfont.woff2') format('woff2'),
         url('#{$fonts}/Rubik-Regular/rubik-regular-webfont.woff') format('woff'),
         url('#{$fonts}/Rubik-Regular/rubik-regular-webfont.ttf') format('truetype'),
         url('#{$fonts}/Rubik-Regular/rubik-regular-webfont.svg#rubikregular') format('svg');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'Arial Rounded MT';
    src: url('#{$fonts}/arial-rounded-mt/ArialRoundedMTBold.eot');
    src: url('#{$fonts}/arial-rounded-mt/ArialRoundedMTBold.eot?#iefix') format('embedded-opentype'),
        url('#{$fonts}/arial-rounded-mt/ArialRoundedMTBold.woff2') format('woff2'),
        url('#{$fonts}/arial-rounded-mt/ArialRoundedMTBold.woff') format('woff'),
        url('#{$fonts}/arial-rounded-mt/ArialRoundedMTBold.ttf') format('truetype'),
        url('#{$fonts}/arial-rounded-mt/ArialRoundedMTBold.svg#ArialRoundedMTBold') format('svg');
    font-weight: bold;
    font-style: normal;
}