@mixin margin($um: 1,$dois: 2){
  
  $um: $um * 1px;
  $dois: $dois * 1px;
  margin:$um $dois;
  
  &:first-child{
    margin-left:0;
    margin-top:0;
  }
  
  &:last-child{
    margin-right:0;
    margin-bottom:0;
  }
  
}