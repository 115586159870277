.btn{
	line-height:1;

	&.btn--responsivo{
		box-shadow:none;

		.bars{
			width:32px;
			height:5px;
			position:relative;
			display:inline-block;
			background-color:$theme2;

			&,
			&:before,
			&:after{
				transition:all 0.6s linear
			}

			&:before{
				top:calc(100% + 4px);
			}

			&:after{
				bottom:calc(100% + 4px);
			}


			@include beforeAfter{
				content:"";
				display:block;
				width:100%;
				position:absolute;
				height:100%;
				background-color:inherit;
				left:0;
			}

		}

	}

	&.btn--pill{
		border-radius: 2rem;
	}

	&.btn--padrao,
	&.btn--padrao-pequeno,
	&.btn--padrao-grande,
	&.btn--padrao-medio{
		border-radius:30px;
		padding:9px 12px;
	}

	&.btn--padrao-diferente{
		border-radius:15px;
		padding:13px 15px;
	}

	&.btn--padrao-medio{
		padding:12px 15px;
	}

	&.btn--radius-25{
		border-radius:25px;
	}

	&.btn--radius-30{
		border-radius:30px;
	}

	&.btn--pdg-15-30{
		padding:15px 30px;
	}

	&.btn--pdg-20-30{
		padding:20px 30px;
	}

	&.btn--padrao{
		min-width:164px;
		font-size:16px;
	}
	
	&.btn--padrao-pequeno{
		font-size:12px;
	}

	&.btn--padrao-grande{
		font-size:24px;
		padding:16px 20px;
		letter-spacing:1px;
	}

	&.btn--fnt-19-mnt-ex-bld{
		font-size:19px;
		font-family: 'Montserrat';
		font-weight:800;
	}

	&.btn--theme{
		@include button-variant($theme,$theme);
		color:#FFF;
	}

	&.btn--theme-2{
		@include button-variant($theme2,$theme2);
		color:#FFF;
	}

	&.btn--67b045{
		@include button-variant(#67b045,#67b045);
		color:#FFF;
	}

	&.btn--theme-to-141414{
		@include button-variant($theme,$theme);
		color:#FFF;

		@include hover-focus{
			@include button-variant(#141414,#141414);
			color:#FFF;
		}

	}

	&.btn--outline-FFF-to-theme{
		@include button-outline-variant(#FFF,#FFF);
		color:#FFF;

		@include hover-focus{
			@include button-variant(#FFF,#FFF);
			color:$theme !important;
		}

	}
	
	&.btn--outline-2c2c2c{
		@include button-outline-variant(#2c2c2c,#2c2c2c);
		color:#000;

		@include hover-focus{
			@include button-variant(lighten(#2c2c2c,5%),lighten(#2c2c2c,5%));
			color:#FFF !important;
		}

	}

	&.btn--outline-282828{
		@include button-outline-variant(#282828,#282828);
		color:#282828;

		@include hover-focus{
			@include button-variant(lighten(#282828,5%),lighten(#282828,5%));
			color:#FFF !important;
		}

	}

	&.btn--outline-FFF-to-theme-2{
		@include button-outline-variant(#FFF,#FFF);
		color:#FFF;

		@include hover-focus{
			@include button-variant(lighten(#FFF,10%),lighten(#FFF,10%));
			color:$theme2 !important;
		}

	}

	&.btn--outline-theme-2-to-theme{
		@include button-outline-variant($theme2,$theme2);
		color:$theme2;

		@include hover-focus{
			@include button-variant($theme,$theme);
			color:#FFF;
		}
		
	}

	&.btn--outline-theme-2-to-209600{
		@include button-outline-variant($theme2,$theme2);
		color:$theme2;

		@include hover-focus{
			@include button-variant(#209600,#209600);
			color:#FFF;
		}

		&[aria-expanded="true"]{
			@include button-variant(#209600,#209600);
			color:#FFF;
		}
		
	}
	
	&.btn--outline-theme-to-theme-theme-2{
		@include button-outline-variant($theme,$theme);
		color:$theme2;

		@include hover-focus{
			@include button-variant(lighten($theme2,15%),lighten($theme2,15%));
			color:#FFF !important;
		}
		
	}

	&.btn--border-3{
		border-width:3px;
	}

	&.btn--border-4{
		border-width:4px;
	}

	&.btn--min-width-146{
		min-width:146px;
	}

	&.btn--min-width-156{
		min-width:156px;
	}

	&.btn--min-width-163{
		min-width:163px;
	}

	&.btn--min-width-184{
		min-width:184px;
	}

	&.btn--min-width-190{
		min-width:190px;
	}

	&.btn--min-width-202{
		min-width:202px;
	}

	&.btn--min-width-220{
		min-width:220px;
	}

	&.btn--min-width-258{
		min-width:180px;

		@include media-breakpoint-up(sm){
			min-width:258px;
		}

	}

	&.btn--min-width-300{
		min-width:180px;

		@include media-breakpoint-up(sm){
			min-width:300px;
		}

	}

}