@import "_home.scss";

.internas__title{
    position:relative;
    font-size:30px;
    line-height:1;
    color:#080808;
    margin-bottom:50px;
    letter-spacing:3px;

    @include media-breakpoint-up(sm){
        font-size:48px;
    }

    &:before{
        content:'';
        display:block;
        background-color:#f98619;
        position:absolute;
        top:calc(100% + 9px);
        height:8px;
        left:6px;
        width:114px;
        border-radius:15px;
    }
    
    &.internas--title-diferente{

        &:before{
            background-color:#29b239;
        }

    }

}

.internas{
    padding-top:78px;
    padding-bottom:100px;
    color:#585858;

    .menu-ano{
        list-style-type:none;
        padding-left:0;
        margin-bottom:0;
        border:2px solid #9b9b9b;
        background-color:#FFF;
        padding:11px 38px 5px 38px;
        z-index:200;
        color:#000;
        font-size:20px;
        border-radius:30px;

        @include media-breakpoint-up(lg){
            position:absolute;
            top:calc(100% - 12px);
            left:0;
            min-width:314px;
        }

        li{

            a{
                display:block;

                @include media-breakpoint-down(md){
                    padding:12px 15px;
                }

            }

            &:hover > a{
                color:$theme2;
            }

            &.active > a{
                color:$theme;
            }

        }

    }

    .link{

        & + .link{
            
            &:before{
                content:'|';
                margin:0 6px 0 4px;
                display:inline-block;

            }

        }

    }

    &.internas--categorias-detalhes{

        button{

            .fas{
                transition:transform 0.6s linear;
            }

            &[aria-expanded="true"]{

                .fas{
                    transform:rotate(180deg);
                }

            }

        }

    }

    .internas__redes-sociais{
        
        & > *{
            font-size:18px;
            width:(32 / 18) * 1em;
            height:(32 / 18) * 1em;
            text-align:center;
            color:#FFF;
            display:inline-flex;
            align-items:center;
            justify-content:center;
            background-color:$theme;
            border-radius:4px;
            @include margin(0,2);

            &.fa-facebook-f{
                background-color:#325b99;
                @include sombra(#325b99);

                @include hover-focus{
                    background-color:darken(#325b99,5%);
                }

            }

            &.fa-instagram{
                background-color:#833AB4;
                @include sombra(#833AB4);

                @include hover-focus{
                    background-color:darken(#833AB4,5%);
                }

            }

            &.fa-linkedin-in{
                background-color:#0e76a8;
                @include sombra(#0e76a8);

                @include hover-focus{
                    background-color:darken(#0e76a8,5%);
                }

            }

            &.fa-twitter{
                background-color:#33abec;
                @include sombra(#33abec);

                @include hover-focus{
                    background-color:darken(#33abec,5%);
                }

            }

            &.fa-google-plus-g{
                background-color:#db4a39;
                @include sombra(#db4a39);

                @include hover-focus{
                    background-color:darken(#db4a39,5%);
                }

            }

        }

    }

    .internas__figure-parceiros{
        box-shadow:0 0 20px rgba(#000,0.3);
        border-radius:6px;
        overflow:hidden;
    }

    &.internas--apae,
    &.internas--parceiros{
        padding-top:130px;
    }

    .carousel{
        box-shadow:0 0 24px rgba(#000,0.4);

        .carousel-item{
            margin-bottom:0;
        }

        .carousel__controls{
            position: absolute;
            top:0;
            left:0;
            width:100%;
            height:100%;
            display:flex;
            align-items:center;
            justify-content:center;
            padding-bottom:23px;

            .carousel__clearfix{
                @include clearfix();
                width:100%;

                & > *{
                    display:block;
                    background-image:var(--icon-arrow);
                    font-size:46px;
                    width:1em;
                    z-index:200;
                    position:relative;
                    height:1em;
                    background-position:center;
                    background-repeat:no-repeat;
                    background-color:#59b034;
                    border-radius:100%;
                    transition:background-color 0.6s linear;

                    &.float-left{
                        transform:rotate(180deg);
                        
                        @include media-breakpoint-up(lg){
                            left:-32px;
                        }

                    }

                    &.float-right{

                        @include media-breakpoint-up(lg){
                            right:-32px;
                        }

                    }

                    &:hover{
                        background-color:$theme2;
                    }

                }

            }

        }

    }

    &.internas--doacoes{
        background-image:var(--bg-doacoes);
        background-repeat:no-repeat;
        background-size:cover;
    }

}