.topo{

    &.topo--index{

        .menu-topo{

            @include media-breakpoint-up(lg){
                padding-top:10px;
            }

        }

    }

    &:not(.topo--index){
        box-shadow:0 0 22px rgba(#000,0.2);
        padding-bottom:6px;
    }

    .bar-topo{
        background-color:#59b037;
        color:#FFF;
        font-size:12px;
        padding-top:2px;
        padding-bottom:2px;
        font-weight:bold;

        @include media-breakpoint-down(md){
            text-align:center;
            padding-bottom:8px;
        }

        .container{
            
            @include media-breakpoint-up(lg){
                display:flex;
                align-items:center;
                justify-content:space-between;
            }

            .redes-sociais-area{
                
                @include media-breakpoint-up(lg){
                    display:flex;
                    align-items:center;
                }

                .redes-sociais-area__text{
                    
                }

                .redes-sociais{
                    
                    @include media-breakpoint-down(md){
                        padding-top:10px;
                    }

                }

            }

        }

    }

    .nav-content{

        @include media-breakpoint-down(md){
            position:fixed;
            left:-230px;
            width:230px;
            height:100%;
            top:0;
            transition:left 0.6s linear;
            overflow:auto;
            border-right:1px solid $theme2;
            background-color:#FFF;
            z-index:1000;
        }

        @include media-breakpoint-up(lg){
            @include make-container();
            @include make-container-max-widths();
            padding-top:12px;
            display:flex;
            align-items:center;
            justify-content:space-between;
        }

        .logo{
            margin-bottom:0;

            @include media-breakpoint-down(md){
                padding:30px 15px;
            }

            @include media-breakpoint-up(lg){
                margin-right:40px;
            }

        }

        .menu-topo{
            list-style-type:none;
            padding-left:0;
            margin-bottom:0;
            display:flex;

            @include media-breakpoint-down(md){
                flex-wrap:wrap;

                & > *{
                    width:100%;
                    order:2;

                    &.li--buscas,
                    &.li--btn{
                        order:1;
                    }

                }

            }

            @include media-breakpoint-up(lg){
                align-items:center;
                flex-grow:1;
            }

            & > li{

                &.li--btn{

                    @include media-breakpoint-down(md){
                        padding-bottom:15px;
                    }

                }

                &.li--btn,
                &.li--buscas{
                    
                    a{

                        &:before{
                            display:none;
                        }

                    }

                }

                & > a{
                    display:block;
                    position:relative;

                    @include media-breakpoint-down(md){
                        padding:10px 15px;
                        border-top:1px solid transparent;
                        border-bottom:1px solid transparent;
                    }

                    @include media-breakpoint-up(lg){

                        &:before{
                            content:'';
                            display:block;
                            position:absolute;
                            top:100%;
                            left:0;
                            width:0;
                            transition:width 0.6s linear;
                            height:2px;
                            background-color:$theme;
                        }

                    }

                }

                & + li{

                    @include media-breakpoint-up(lg){
                        margin-left:22px;
                    }

                }

                &.li--btn{

                    @include media-breakpoint-down(md){
                        text-align:center;
                        border-top:20px solid transparent;
                    }

                    & > a{
                        padding:4px 12px;
                        @include button-variant($theme2,$theme2);
                        color:#FFF;
                        border-radius:30px;

                        @include hover-focus{
                            @include button-variant(#141414,#141414);
                            color:#FFF;
                        }

                        @include media-breakpoint-down(md){
                            display:inline-block;
                        }

                    }

                }

                &.li--buscas{

                    @include media-breakpoint-down(md){
                        text-align:center;
                        padding-top:12px;
                        margin-bottom:24px;
                    }

                    .btn{
                        padding:0;
                        border:none;
                        box-shadow:none;

                        .icone{
                            width:22px;
                            height:22px;
                            display:inline-block;
                            background-image:var(--icon-buscas);
                        }

                    }

                }

                &.active,
                &:hover{

                    & > a{

                        @include media-breakpoint-down(md){
                            border-color:darken($theme2,10%);
                            background-color:$theme2;
                            color:#FFF;
                        }
                        
                        @include media-breakpoint-up(lg){
                            color:$theme2;

                            &:before{
                                width:100%;
                            }

                        }

                    }

                    &.li--btn{

                        & > a{

                            @include media-breakpoint-up(lg){
                                color:#FFF;
                                background-color:darken($theme2,10%);
                            }

                        }

                    }

                }

                &.dropdown{

                    & > a{

                        @include media-breakpoint-down(md){

                            &:before{
                                content:"\f078";
                                display:inline-block;
                                color:currentColor;
                                font-family:'Font Awesome 5 Free';
                                font-weight:900;
                                margin-right:6px;
                            }

                        }

                    }

                    .dropdown-menu{
                        position: absolute;
                        top:100%;
                        left:50%;
                        min-width:170px;
                        font-size:14px;
                        border-radius:30px;
                        background-color:#f9851c;
                        float:none;
                        transform:translateX(-50%);
                        margin:0;
                        color:#FFF;
                        border:none;

                        @include media-breakpoint-up(lg){
                            white-space:nowrap;
                        }

                        @include media-breakpoint-down(md){
                            position:static;
                            transform:none;
                            width:100%;
                            display:block;
                            border-radius:0;

                            a{

                                &:before{
                                    content:'-';
                                    display:inline-block;
                                    vertical-align:text-bottom;
                                    margin-right:6px;
                                }

                            }

                        }

                        li{
                            margin-bottom:0px;

                            & > a{
                                display:block;
                                padding:10px 15px;
                            }

                            &.active,
                            &:hover{

                                a{
                                    color:#000;
                                }

                            }

                        }

                    }

                    &:hover{

                        @include media-breakpoint-up(lg){

                            .dropdown-menu{
                                display:block !important;
                            }

                        }

                    }

                }

            }

        }

    }

    &.fx{

        .mbl-controls{
            box-shadow: 0 0 8px rgba(#000,0.5);
            position:fixed;
            z-index:600;
            width:100%;
            top:0;
            left:0;
            animation:fadeInDown 0.6s linear;
        }

    }

}