.segura-carousel{
    position:relative;

    .owl-stage-outer{
        z-index:15;
    }

    .owl-nav{
        position: absolute;
        top:0;
        left:0;
        width:100%;
        height:100%;
        display:flex;
        align-items:center;
        justify-content:space-between;
        padding-bottom:23px;

        & > *{
            position:relative;
            z-index:200;

            &.disabled{
                opacity:0.5;
            }
            
            & > *:not(.sr-only){
                display:block;
                background-image:var(--icon-arrow);
                font-size:46px;
                width:1em;
                position:relative;
                height:1em;
                background-position:center;
                background-repeat:no-repeat;
                background-color:#59b034;
                border-radius:100%;
                transition:background-color 0.6s linear;
            }

            &.owl-prev{
                transform:rotate(180deg);
                
                @include media-breakpoint-up(lg){
                    left:-22px;
                }

            }

            &.owl-next{

                @include media-breakpoint-up(lg){
                    right:-22px;
                }

            }

            &:hover{
                background-color:$theme2;
            }

        }

    }

    // 23 (METADE DAS FLECHAS PADROES VERDES) + MGB DOS CARDS OU ITENS NO CAROUSEL PARA DEIXAR NO CENTRO

    &.servicos__segura-carousel{

        .owl-carousel{

            .owl-stage{

                @include media-breakpoint-up(sm){
                    
                    &{
                        display:flex;
                        flex-wrap:wrap;
                    }

                }

            }

            .owl-item{
                padding:16px 0;

                @include media-breakpoint-up(sm){
                    
                    &,
                    & > *{
                        display:flex;
                    }

                    .card-servicos{
                        display:flex;
                        flex-direction:column;

                        .card-servicos__desc{
                            flex-grow:1;
                        }

                    }

                }

            }
    
        }

        .owl-nav{
            padding-bottom:23px + 28px;
        }

    }

    &.nossos-parceiros__segura-carousel{

        .owl-nav{
            padding-bottom:23px + 30px;
        }

    }

    &.noticias__segura-carousel{

        .owl-item{
            padding-top:6px;
            padding-bottom:6px;
        }

        .owl-nav{
            padding-bottom:23px + 28px;
        }

    }

}

.redes-sociais{
    line-height:1;
    padding-top:2px;

    & > *{
        @include margin(0,6);
        font-size:14px;

        @include hover-focus(){
            color:$theme2;
        }

    }

    &.rodape-info__redes-sociais{
        
        & > *{
            background-color:#151515;
            width:(21 / 14) * 1em;
            height:(21 / 14) * 1em;
            border-radius:100%;
            color:#FFF;
            display:inline-flex;
            align-items:center;
            justify-content:center;

            @include hover-focus{
                background-color:$theme;
            }

        }

    }

}

.banner{

    &:before{
        content:'';
        display:block;
        width:100%;
        position: absolute;
        bottom:0;
        z-index:300;
        left:0;
        height:137px;
        pointer-events:none;
        background-image:var(--bg-ondas-banner);

        @include media-breakpoint-down(md){
            background-position:center;
        }

    }

    .carousel-item{
        margin-bottom:0;
    }

}

.carousel{

    .carousel-indicators{
        display:flex;
        align-items:flex-end;
        justify-content:flex-end;
        flex-direction:column;
        padding-bottom:118px;
        width:100%;
        margin:0 auto;
        top:0;
        left:0;
        height:100%;
        @include make-container-max-widths();
        z-index:unset;

        & > *{
            font-size:30px;
            width:1em;
            height:1em;
            border-radius:100%;
            border:2px solid currentColor;
            color:#FFF;
            opacity:1;
            z-index:400;
            background-color:transparent;
            position:relative;
            margin-bottom:(14 / 30) * 1em;

            &:last-child{
                margin-bottom:0;
            }

            &:before{
                content:'';
                display:block;
                font-size:4px;
                width:1em;
                height:1em;
                border-radius:100%;
                background-color:transparent;
                position: absolute;
                transition:background-color 0.6s linear;
                top:50%;
                left:50%;
                transform:translate(-50%,-50%);
            }

            &.active{

                &:before{
                    background-color:#FFF;
                }

            }

        }
        
    }

}

.bg--index{
    
}

.sobre{
    padding-top:14px;

    .col--text{
        z-index:100;
    }

    .sobre__figure{
        position:relative;
        z-index:0;

        &:before{

            @include media-breakpoint-up(lg){
                content:'';
                display:block;
                width:1029px;
                height:733px;
                background-repeat:no-repeat;
                background-image:var(--bg-conheca-before);
                position:absolute;
                top:74px;
                left:-190px;
                z-index:-1;
            }
            
        }

    }

}

.servicos{
    position:relative;
    padding-bottom:42px;

    &:before{
        content:'';
        display:block;
        width:100%;
        height:236px;
        position: absolute;
        bottom:0;
        background-image:var(--bg-servicos);

        @include media-breakpoint-down(md){
            background-position:center center;
        }

    }

}

.nossos-parceiros{
    padding-top:40px;
    padding-bottom:36px;
}

.noticias{
    background-color:#f6f6f6;
    padding-top:24px;
    padding-bottom:2px;

    .noticias__ver-mais{
        font-size:16px;
    }

}