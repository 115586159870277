@import "_fontfaces";

html{
	scroll-behavior:smooth;
}

body{
    font-size:14px;
    font-family: 'Arial Rounded MT';
    color:#1d1d1d;
}

// FONTS

.row--ajeitar-card{

	& > *{

		@include media-breakpoint-up(sm){
			display:flex;
		}

		& > *{

			@include media-breakpoint-up(sm){
				display:flex;
			}

		}

		.card-servicos{

			@include media-breakpoint-up(sm){
				display:flex;
				flex-direction:column;
			}

			.card-servicos__desc{
				flex-grow:1;
			}

		}

	}

}

.embed--responsive-1110x434{

	&:before{
		padding-top:(434 / 1110) * 100%;
	}

}

.font--fnt-16{
    font-size:16px;
}

.font--fnt-18{
    font-size:18px;
}

.font--fnt-24{
    font-size:24px;
}

.font--fnt-29{
    font-size:29px;
}

.font--fnt-36{
	font-size:28px;
	
	@include media-breakpoint-up(sm){
		font-size:36px;
	}

}

.line--height-1-2{
    line-height:1.2;
}

.line--height-1-5{
    line-height:1.5;
}

.font--fnt-111{
    font-size:30px;

    @include media-breakpoint-up(md){
        font-size:111px !important;
    }

}

.font--arial-rounded-mt{
    font-family: 'Arial Rounded MT';
}

.font--rubik-reg{
    font-family: 'rubikregular';
}

.font--mnt-light{
	font-family: 'Montserrat';
	font-weight:300;
}

.font--mnt-ex-bld{
	font-family: 'Montserrat';
	font-weight:800;
}

// FIM FONTS

.mb-30{
	margin-bottom:30px !important;
}

@keyframes giraSol{

	from{
		transform:rotate(0deg);
	}

	to{
		transform:rotate(360deg);
	}

}

.text--FFF{
    color:#FFF !important;
}

.text--585858{
	color:#585858 !important;
}

.text--000{
	color:#000000 !important;
}

.text--2c1e1f{
	color:#2c1e1f !important;
}

.text--ffc000{
	color:#ffc000 !important;
}

.text--29b23b{
	color:#29b23b !important;
}

@mixin make-container-max-widths($max-widths: $container-max-widths, $breakpoints: $grid-breakpoints) {
  @each $breakpoint, $container-max-width in $max-widths {
    @include media-breakpoint-up($breakpoint, $breakpoints) {
      max-width: $container-max-width;
    }
  }
}

#app{
	position:relative;
	overflow:hidden;
}


.slick-slider{
	text-align:center;

	.slick-track{
		
		@include media-breakpoint-up(sm){
			display:flex;
			flex-wrap:wrap;
			align-items:flex-end;
		}

	}

}

.logo{
	margin-bottom:0;
}

.internas img{
	@include img-fluid();
}

a,
.btn{
	transition:all 0.6s linear;
}

a{
	color:inherit;
	text-decoration:none;

	@include hover-focus{
		color:inherit;
		text-decoration:none;
	}

}

*[data-animate]{
	opacity:0;
}

.animated{
	animation-direction:1.4s;
	animation-fill-mode: both;
}

.animated[data-animate]{
	opacity:1;
}

.atraso-1{
	animation-delay:0;
}

.atraso-2{
	animation-delay:0.2s;
}

.atraso-3{
	animation-delay:0.4s;
}

.atraso-4{
	animation-delay:0.6s;
}

lazy-image{
	background-color:transparent;
}

.slick-slider{

	img{
		display:inline-block !important;
	}

}

.carousel{
	text-align:center;

	.carousel-item{
		position: relative;
		z-index:200;
	}

}

:focus{
	outline:none !important;
}

picture{
	display:inline-block;
}

.font--italic{
	font-style:italic;
}

// concerta erro meu (João) de sprites em outros projetos
// .icon{

// 	@each $icon,$value in $icons {
// 		&.#{$icon} {
// 			width: nth($value,3);
// 			height: nth($value,4);
// 			background-position: #{nth($value,1)} #{nth($value,2)};
// 		}
// 	}
// }

[data-desligador="true"]{
	display:none;
	background-color:rgba(#000,0.6);
	position:fixed;
	top:0;
	left:0;
	width:100%;
	height:100%;
	z-index:700;
	cursor:pointer;
}

@keyframes explodeMidia{

	0%{
		opacity:1;
		transform:scale(1);
	}

	100%{
		opacity:0;
		transform:scale(18);
	}

}

body{
	transition:padding-left 0.6s linear;

	&.menu-active{

		.btn--responsivo{

			.bars{
				transform:rotate(45deg);

				@include beforeAfter{
					transform:rotate(-90deg);					
				}

				&:before{
					top:0;
				}

				&:after{
					bottom:0;
				}

			}

		}

		.topo{

			.nav-content{
				left:0;
			}

		}

	}

}