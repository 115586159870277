.form-contato{

    form{

        .form-group,
        .input-group{
            margin-bottom:11px;
            
            .form-control{
                min-height:41px;
                border:3px solid #f99200;
                border-radius:30px;
                font-size:14px;
                @include place(#fd931b);
                padding-left:12px;
                @include sombra(#fd931b);
            }

            textarea.form-control{
                padding-top:12px;
                resize:none;
                padding-left:15px;
            }

        }

    }

}

.form-sem-label{

    form{

        .form-group,
        .input-group{
            
            label:not(.label-on){
                @include sr-only();
            }

        }

    }

}

.form-buscas{

    form{

        .input-group,
        .form-group{

            .form-control{
                @include sombra(#FFF);
            }

            .input-group-prepend{
                margin-right:0;

                .btn{
                    min-width:60px;
                }

            }

        }

    }

}

.form-doacoes{

    form{

        .input-group,
        .form-group{
            margin-bottom:10px;

            .form-control{
                min-height:50px;
                background-color:#ededed;
                border-radius:30px;
                border-color:#ededed;
                padding-left:36px;
                font-size:13px;
                @include sombra(darken(#ededed,10%));
                @include place(#aaaaaa);
                font-weight:bold;
            }

        }

        .radio-custom{

            label{
                font-size:18px;
                color:#222;

                .circle{
                    font-size:26px;
                    width:1em;
                    height:1em;
                    border-radius:100%;
                    display:inline-block;
                    border:3px solid #000;
                    vertical-align:middle;
                    margin-right:10px;
                    position:relative;

                    &:before{
                        content:'';
                        display:block;
                        position: absolute;
                        font-size:14px;
                        width:1em;
                        height:1em;
                        border-radius:100%;
                        top:50%;
                        opacity:0;
                        transition:opacity 0.6s linear;
                        background-color:#000;
                        left:50%;
                        transform:translate(-50%,-50%);
                    }

                }

            }

            input{
                position:fixed;
                top:0;
                left:-200vw;

                &:checked ~ label{

                    .circle{

                        &:before{
                            opacity:1;
                        }

                    }

                }

            }

        }

    }

}
