.paginacao{
	display: flex;
	flex-wrap: wrap;

	&.paginacao__start{
		justify-content: flex-start;
	}

	&.paginacao__center{
		justify-content: center;
	}

	&.paginacao__end{
		justify-content: flex-end;
	}

	&.paginacao__between{
		justify-content: space-between;
	}

	.page__item {
		display: inline-block;
		padding: 5px 10px;
		border: 1px solid #CCC;
		color: inherit;
		font-size:18px;
		width:(40 / 18) * 1em;
		height:(40 / 18) * 1em;
		display:inline-flex;
		align-items:center;
		justify-content:center;
		@include margin(0,6);
		border-radius:100%;
		background-color:#fbbe50;
		border-color:#fbbe50;
		color:#333130;
	}

	a.page__item:hover{
		background-color:lighten(#f98619,15%);
		border-color:lighten(#f98619,15%);
	}

	span.page__item--active{
		background-color:#f98619;
		border-color:#f98619;
	}
}
