.card-servicos{
    margin-bottom:28px;
    text-align:center;
    background-color:#FFF;
    border-radius:8px;
    box-shadow:0 0 16px rgba(#000,0.15);
    padding-bottom:26px;
    padding:26px 28px 26px 28px;
    max-width:260px;
    margin-left:auto;
    margin-right:auto;

    .card-servicos__title{
        font-size:18px;
        color:#f88a02;
        margin-bottom:12px;
        transition:color 0.6s linear;
    }

    .card-servicos__desc{
        color:#656565;
        margin-bottom:12px;
        line-height:1.2;
        font-size:14px;
    }

    &:hover{

        .card-servicos__title{
            color:$theme;
        }
        
    }

}

.card-midia{
    margin-left:auto;
    margin-right:auto;

    .card-midia__video-code{
        display:none !important;
        position:fixed;
        top:-200vw;
        left:0;
    }

    .card-midia__segura-video{
        cursor: pointer;
        position:relative;
        background-repeat:no-repeat;
        background-size:cover;
        border-radius:9px;

        @include media-breakpoint-down(md){
            
            &,
            .embed-responsive{
                min-height:200px;
            }

        }

        &:before{
            content:'';
            display:block;
            position:absolute;
            background-image:var(--icone-youtube);
            width:99px;
            height:99px;
            top:50%;
            transform:translate(-50%,-50%) scale(0);
            z-index:22;
            left:50%;
            transition:transform 0.6s linear;
        }

        &[style='']{

            &:before{
                display:none;
            }

        }

    }

    &:hover{

        .card-midia__segura-video{

            &:before{
                transform:translate(-50%,-50%) scale(1);
            }

        }

    }

}

.tab-custom{
    margin-bottom:22px;

    .btn{
        width:100%;
        border-radius:30px;
        text-align:left;
        padding-left:16px;
        display:flex;
        align-items:center;
        justify-content:space-between;
        padding:7px 18px;

        .fas{
            transition:transform 0.6s linear;
        }

        &[aria-expanded="true"]{

            .fas{
                transform:rotate(180deg);
            }

        }

    }

    .tab-custom__content{
        padding-top:14px;
        line-height:1.2;
    }

    &.index__tab-custom{
        color:#585858;
    }

}

.card-parceiros{
    margin-bottom:30px;
}

.article-parceiros{
    margin-bottom:32px;

    @include media-breakpoint-down(md){
        text-align:center;
    }

    .row{
        align-items:center;
    }

    .article-parceiros__figure{

        @include media-breakpoint-down(md){
            margin-bottom:0;
            padding-bottom:24px;
        }

    }

    .article-parceiros__title{
        
        color:#f8a403;
        line-height:1;
        margin-bottom:18px;
        font-size:25px;

        @include media-breakpoint-up(sm){
            font-size:36px;
        }

    }

    .article-parceiros__desc{
        color:#585858;
        font-size:13px;
    }
    
}

.article-transparencia{
    background-color:#29b13a;
    color:#FFF;
    border-radius:50px;
    padding-left:32px;
    padding-right:23px;
    padding-top:16px;
    padding-bottom:16px;
    transition:background-color 0.6s linear;
    margin-bottom:18px;

    .icone{
        width:45px;
        height:37px;
        display:block;
        background-image:var(--icon-pastas);

        @include media-breakpoint-down(md){
            margin-bottom:12px;
        }

    }

    &:hover{
        background-color:$theme2;
    }

    .article-transparencia__text{

        @include media-breakpoint-down(md){
            margin-bottom:12px;
        }

    }

    &.article-transparencia--diferente{
        background-color:#67b045;
        padding-top:15px;
        padding-bottom:15px;
        margin-bottom:24px;

        &:hover{
            background-color:#ee852a;
        }

        .btn{
            min-width:154px;
            text-transform:lowercase;
            text-align:left;
            padding:7px 20px;
            font-weight:bold;
        }

    }

}

.card-parceiros{
    text-align:center;
}

.card-noticias{
    border-radius:8px;
    overflow:hidden;
    margin-bottom:28px;
    box-shadow:0 0 6px rgba(#000,0.15);
    max-width:350px;
    margin-left:auto;
    margin-right:auto;
    background-color:#FFF;

    @include media-breakpoint-down(md){
        text-align:center;
    }

    @include media-breakpoint-up(lg){
        display:flex;
    }

    .card-noticias__figure{
        margin-bottom:0;
    }

    .card-noticias__content{
        padding:32px 10px 8px 6px;
        position:relative;

        .card-noticias__title{
            margin-bottom:11px;
        }

        .card-noticias__desc{
            margin-bottom:10px;
            line-height:1.2;
            @include line-clamp(4);
        }

        .card-noticias__title,
        .card-noticias__date,
        .card-noticias__icone{
            color:#f88600;
            transition:color 0.6s linear;
        }

        .card-noticias__icone{
            position:absolute;
            bottom:7px;
            right:9px;
            font-size:23px;
        }

    }

    &:hover{

        .card-noticias__content{

            .card-noticias__title,
            .card-noticias__date,
            .card-noticias__icone{
                color:$theme;
            }

        }

    }

}

.article-doacoes{
    margin-bottom:28px;
    
    .article-doacoes__title{
        background-color:#f9851c;
        color:#FFF;
        font-size:16px;
        border-radius:30px;
        padding:4px 16px;
        margin-bottom:16px;
    }

    .article-doacoes__desc{
        line-height:1.2;
    }

}

.card-categoria{
    border:5px solid currentColor;
    border-radius:22px;
    padding:12px 55px;
    min-height:80px;
    display:flex;
    align-items:center;
    justify-content:center;
    text-align:center;
    color:#ff8717;
    transition:all 0.6s ease;
    margin-bottom:20px;

    .card-categoria__title{
        font-size:16px;
        color:inherit;
        line-height:1.2;
        font-weight:bold;
        font-family: 'Arial Rounded MT';
    }

    &:hover{
        background-color:#009600;
        border-color:#009600;
        color:#FFF;
    }
    
}