.rodape{

    @include media-breakpoint-down(md){
        text-align:center;
    }

    .lazy-embed{

        @include media-breakpoint-down(md){
            margin-right:auto;
            margin-left:auto;
        }

    }

    .rodape-doacao{
        color:#FFF;
        font-weight:bold;
        padding-bottom:17px;
        padding-top:86px;
        background-repeat:repeat-x;
        background-size:cover;
        position:relative;

        .container{
            position:relative;

            .rodape-doacao__girassol{
                position: absolute;
                top:0;

                @include media-breakpoint-down(sm){
                    display:none;
                }
    
                @include beforeAfter{
                    content:'';
                    display:block;
                    position: absolute;
                    width:100%;
                    left:0;
                }
    
                &:before{
                    animation:giraSol 5s alternate infinite;
                }
    
                &:after{
                    background-position:center bottom;
                }
    
                &.girassol-grande{
                    width:105px;
                    height:156px;
                    top:unset;
                    
                    @include media-breakpoint-between(sm,md){
                        top:-80px;
                        left:-50px;
                    }
                    
                    @include media-breakpoint-up(lg){
                        left:-240px;
                        bottom:calc(100% + 44px);
                    }
    
                    @include beforeAfter{
                        background-image:var(--bg-girassol-grande);
                    }
    
                    &:before{
                        top:0;
                        height:104px;
                        transform-origin:52px 46px;
                    }
    
                    &:after{
                        bottom:0;
                        height:52px;
                    }
    
                }
    
                &.girassol-pequeno{
                    width:77px;
                    height:118px;
                    left:230px;
                    top:-80px;

                    @include media-breakpoint-between(sm,md){
                        top:-80px;
                        left:calc(100% - 40px);
                    }

                    @include media-breakpoint-up(lg){
                        left:-100px;
                        bottom:calc(100% + 52px);
                        top:unset;
                    }
    
                    @include beforeAfter{
                        background-image:var(--bg-girassol-pequeno);
                    }
    
                    &:before{
                        top:0;
                        height:76px;
                        transform-origin:38px 34px;
                    }
    
                    &:after{
                        bottom:0;
                        height:42px;
                    }
    
                    &.lado-direito{

                        @include media-breakpoint-up(lg){
                            left:unset;
                            right:calc(0% - 180px);
                            bottom:calc(100% + 48px);
                        }

                    }
    
                }
    
            }

        }

        &:before{
            content:'';
            display:block;
            width:100%;
            height:214px;
            position: absolute;
            bottom:0;
            left:0;
            z-index:-1;
            background-color:#219800;
        }

        .rodape-doacao__title{
            
            .icone{
                background-image:var(--icone-doacao);
                display:inline-block;
                width:93px;
                height:101px;
            }

        }

    }

    .rodape-info{
        padding-top:16px;
        padding-bottom:4px;
        font-size:14px;
        font-weight:bold;

        .container{

            & > .row > *{
                margin-bottom:12px;
            }

        }

        .redes-sociais{
            text-align:center;
        }

    }

    .rodape-copyright{
        background-color:#313131;
        color:#FFF;
        text-align:center;
        font-size:9px;
        font-family: 'rubikregular';
        font-weight:bold;
        padding-top:13px;
        padding-bottom:9px;
        line-height:1;

        .gv8{
            max-width:56px;
            margin-left:11px;
        }

    }

}

.topo.topo--index ~ .rodape .rodape-doacao{
    background-color:#f6f6f6;
}